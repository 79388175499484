/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-19",
    versionChannel: "nightly",
    buildDate: "2024-07-19T00:05:33.911Z",
    commitHash: "3cb798e8181c2ea2acb94c6dfac79cfa130a94cd",
};
